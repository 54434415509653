import { TRACK_SOURCE_TYPE } from '../constants/trackSourceType';

const createTrackSources = (selectedSources) => {
  return selectedSources.reduce((acc, { source, sourceShort, country_tag }) => {
    acc[source] = {
      source,
      sourceShort,
      country_tag,
      status: TRACK_SOURCE_TYPE.MISSING,
    };
    return acc;
  }, {});
};

export const prepTracksWithSources = ({ tracks, selectedSources }) => {
  let tracksWithSources = {};
  for (const track of tracks) {
    if (!(track.objectID in tracksWithSources)) {
      tracksWithSources[track.objectID] = {
        track,
        sources: createTrackSources(selectedSources),
      };
    }
    const countrySourceCounter = new Set();
    const currentTrackSources = tracksWithSources[track.objectID].sources;
    if (!track.registrations) {
      console.warn(`No registrations found for track ${track.title}`);
      continue;
    }
    for (const registration of track.registrations) {
      try {
        const {
          societyData: { country_tag },
          databaseId,
          society: source,
        } = registration;
        const countrySourceKey = `${country_tag}-${databaseId}`;
        /**
         * The country was not added yet, so we can't have a duplicate for it.
         * Therefore, we just add it as found and add the countrySourceKey to check against later.
         */
        if (!countrySourceCounter.has(countrySourceKey)) {
          currentTrackSources[source].status = TRACK_SOURCE_TYPE.FOUND;
          countrySourceCounter.add(countrySourceKey);
          continue;
        }

        /**
         * If we already have the country in countriesStatus we change the colour to duplicate
         */
        currentTrackSources[source].status =
          TRACK_SOURCE_TYPE.POTENTIAL_DUPLICATE;
        // if (track.workcodes.length === 1) {
        //   currentTrackSources[track.source].status = TRACK_SOURCE_TYPE.FOUND;
        // } else if (track.workcodes.length > 1) {
        //   currentTrackSources[track.source].status =
        //     TRACK_SOURCE_TYPE.POTENTIAL_DUPLICATE;
        // }
      } catch (error) {
        // console.error(error);
        // console.warn(`Error for ${registration.source}`);
        continue;
      }
    }
  }
  return tracksWithSources;
};
