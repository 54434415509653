<script>
import { fetchTracksDetailsForDownload } from '@/api/catalogApi';
import { IDLE, PENDING, SUCCESS, ERROR } from '@/constants/apiStatus';
import { apiStatusComputedFactory } from '@/api/helpers/apiStatusComputedFactory';
import { withAsync, downloadFile } from '@/helpers';
import dayjs from 'dayjs';
export default {
  name: 'SelectTracksDownloadProvider',
  data() {
    return {
      selectedTracksForDownload: {},
      selectedTracksForDownloadCount: 0,
      downloadSelectedTracksStatus: IDLE,
    };
  },
  render(h) {
    const {
      selectedTracksForDownload,
      resetTracksForDownload,
      addTrackForDownload,
      removeTrackForDownload,
      selectedTracksForDownloadCount,
      downloadSelectedTracks,
      downloadSelectedTracksStatusIdle,
      downloadSelectedTracksStatusPending,
      downloadSelectedTracksStatusSuccess,
      downloadSelectedTracksStatusError,
    } = this;
    return this.$scopedSlots.default({
      selectedTracksForDownload,
      resetTracksForDownload,
      addTrackForDownload,
      removeTrackForDownload,
      selectedTracksForDownloadCount,
      downloadSelectedTracks,
      downloadStatus: {
        downloadSelectedTracksStatusIdle,
        downloadSelectedTracksStatusPending,
        downloadSelectedTracksStatusSuccess,
        downloadSelectedTracksStatusError,
      },
    });
  },
  computed: {
    ...apiStatusComputedFactory('downloadSelectedTracksStatus'),
  },
  methods: {
    resetTracksForDownload() {
      // this.selectedTracksForDownload = {};
      this.$set(this, 'selectedTracksForDownload', {});
      this.selectedTracksForDownloadCount = 0;
    },
    addTrackForDownload(paramOne, paramTwo) {
      if (Array.isArray(paramOne)) {
        for (const [track, key = 'id'] of paramOne) {
          // Continue the loop if the track is already present
          if (track[key] in this.selectedTracksForDownload) {
            continue;
          }
          this.selectedTracksForDownload[track[key]] = track;
          this.selectedTracksForDownloadCount++;
        }
        return;
      }
      const track = paramOne;
      const key = paramTwo || 'id';
      this.selectedTracksForDownload[track[key]] = track;
      this.selectedTracksForDownloadCount++;
    },
    removeTrackForDownload(track, key = 'id') {
      delete this.selectedTracksForDownload[track[key]];
      this.selectedTracksForDownloadCount--;
    },
    async downloadSelectedTracks(
      tracksToDownload = this.selectedTracksForDownload
    ) {
      this.downloadSelectedTracksStatus = PENDING;
      const tracksToDownloadResult = await tracksToDownload;
      const { response, error } = await withAsync(() =>
        fetchTracksDetailsForDownload(
          this.prepareFetchTrackDetailsForDownloadPayload(
            tracksToDownloadResult
          )
        )
      );

      if (error) {
        console.error(error);
        this.downloadSelectedTracksStatus = ERROR;
        return;
      }

      const { tracks } = response;

      if (!tracks.length) {
        alert('No tracks details found.');
        return;
      }

      const headers = Object.keys(tracks?.[0]);

      const preparedData = tracks.map((obj) => {
        return Object.values(obj)
          .map((item) => `"${item}"`)
          .join(',');
      });

      downloadFile({
        data: [headers, ...preparedData].join('\n'),
        fileName: `Rightsholder_report_${dayjs().format('YYYY-MM-DD')}`,
        fileType: 'text/csv',
      });

      this.downloadSelectedTracksStatus = SUCCESS;
    },
    prepareFetchTrackDetailsForDownloadPayload(selectedTracks) {
      return {
        objectIDs: Object.values(selectedTracks).map((track) => track.objectID),
      };
    },
  },
};
</script>
