<template>
  <SelectTracksDownloadProvider>
    <template
      #default="{
        selectedTracksForDownload,
        addTrackForDownload,
        removeTrackForDownload,
        selectedTracksForDownloadCount,
        downloadSelectedTracks,
        downloadStatus,
        resetTracksForDownload,
      }"
    >
      <TrackDetailsProvider
        :selectedTrack="selectedTrack"
        :tracksBySource="tracksBySource"
        :sourcesById="sourcesById"
        ref="trackDetailsProvider"
        @onSearchByPartyName="onSearchByPartyName"
      >
        <template
          #default="{
            openCountryDetails,
            searchCountries,
            highlightCountries,
            resetCountriesColour,
            closeCountryDetailsCard,
            closeCompareCard,
          }"
        >
          <UserTracksListModal
            :tracks="selectedTracksForDownload"
            @changeMode="onChangeMode"
          >
            <template #default="{ showTracksListModal }">
              <div :class="[appliedFilters.length && $style.hasFilters]">
                <div :class="$style.logoContainer">
                  <img
                    :class="$style.logo"
                    src="@/assets/img/logo-sm.png"
                    alt="Rightsholders Logo"
                  />
                </div>

                <div :class="$style.pageContent">
                  <div v-if="selectedTrack" :class="$style.selectedTrackBlock">
                    <button
                      :class="$style.closeSelectedTrackBtn"
                      @click="
                        (selectedTrack = null),
                          resetCountriesColour(),
                          closeCountryDetailsCard(),
                          closeCompareCard()
                      "
                    >
                      <BaseIcon icon="times" />
                    </button>
                    <div :class="$style.selectedTrack">
                      <span :class="$style.selectedTrackTitle">
                        {{ selectedTrack.title }}
                      </span>
                      <span :class="$style.trackRegistrationsSummary"
                        >{{
                          Array.isArray(selectedTrack.registrations)
                            ? selectedTrack.registrations.length
                            : 0
                        }}
                        registrations</span
                      >
                    </div>
                  </div>
                  <template v-else>
                    <div :class="$style.searchInputContainer">
                      <input
                        type="text"
                        :class="[
                          $style.searchInput,
                          'h-[42px] placeholder:text-[#6B696B]',
                        ]"
                        :value="query"
                        :placeholder="searchInputPlaceholder"
                        @input="
                          onSearchQueryChange($event, resetTracksForDownload)
                        "
                      />
                      <div
                        :class="$style.filtersContainer"
                        v-if="appliedFilters.length"
                      >
                        <span :class="$style.matchesFound"> Filters: </span>
                        <div
                          v-for="(filterValue, filterKey) of appliedFilters"
                          :key="`${filterValue.key}-${filterKey}`"
                          variant="primary-outline"
                        >
                          <BasePill
                            variant="primary-outline"
                            isClearable
                            @clear="removeFilter(filterValue)"
                          >
                            {{ filterValue.label }}
                          </BasePill>
                        </div>
                      </div>
                      <div
                        :class="$style.compositionsFoundContainer"
                        v-if="nbHits"
                      >
                        <div v-if="nbHits <= 1000">
                          <IconCheckbox
                            :checked="
                              distinctTracks.length ===
                                selectedTracksForDownloadCount
                            "
                            @change="
                              onSelectAllTracks(
                                $event,
                                addTrackForDownload,
                                resetTracksForDownload
                              )
                            "
                          />
                        </div>
                        <span :class="$style.matchesFound"
                          ><b>{{ uniqueCompositions }}</b> Unique Compositions
                        </span>
                      </div>
                    </div>
                  </template>
                  <router-view
                    v-if="tracks.length && fetchSourcesStatusSuccess"
                    :searchStatsClass="$style.searchStats"
                    :tracks="tracks"
                    :distinctTracks="distinctTracks"
                    :sources="sources"
                    :topSources="topSources"
                    :tracksBySource="tracksBySource"
                    :selectedTrack="selectedTrack"
                    :isLastPage="isLastPage"
                    :selectedTracksForDownload="selectedTracksForDownload"
                    :addTrackForDownload="addTrackForDownload"
                    :removeTrackForDownload="removeTrackForDownload"
                    :selectedTracksForDownloadCount="
                      selectedTracksForDownloadCount
                    "
                    :downloadStatus="downloadStatus"
                    :allRegistrationsCount="nbHits <= 1000 ? nbHits : 0"
                    :isDemo="isDemo"
                    :searchStats="selectedTrack ? null : searchStats"
                    :appliedFilters="appliedFilters"
                    @showTracksListModal="showTracksListModal('SAVE_TRACKS')"
                    @openSearchListsModal="showTracksListModal('SEARCH_LISTS')"
                    @openCountryDetails="openCountryDetails"
                    @loadMoreTracks="
                      searchCatalogTracks(query, page + 1, $route.query.listId)
                    "
                    @downloadSelectedTracks="downloadSelectedTracks"
                    @downloadAllRegistrations="
                      onDownloadAllRegistrations(downloadSelectedTracks)
                    "
                    @resetSelectedTracks="resetTracksForDownload"
                    @trackSelected="
                      onTrackSelected({
                        track: $event,
                        highlightCountries,
                        searchCountries,
                      })
                    "
                    @addFilter="addFilter"
                  />
                  <div
                    :class="$style.noResultsFound"
                    v-else-if="
                      !fetchSourcesStatusPending && !searchTracksStatusPending
                    "
                  >
                    <span>No results found</span>
                  </div>
                </div>
              </div>
            </template>
          </UserTracksListModal>
        </template>
      </TrackDetailsProvider>
    </template>
  </SelectTracksDownloadProvider>
</template>
<script>
import { apiStatusComputedFactory } from '@/api/helpers/apiStatusComputedFactory';
import {
  searchCatalogTracks,
  fetchSearchAnalytics,
  fetchAllTracksCount,
} from '@/api/catalogApi';
import { fetchSources } from '@/api/sourceApi';
import { withAsync } from '@/helpers';
import { debounce } from 'lodash-es';
import { API_STATUS } from '@/constants/apiStatus';
import DetailsCard from './components/DetailsCard';
import TrackDetailsProvider from './components/TrackDetailsProvider';
import SelectTracksDownloadProvider from './components/SelectTracksDownloadProvider';
import { prepTracksWithSources } from './helpers/prepTracksWithSources';
import { TRACK_SOURCE_TYPE } from './constants/trackSourceType';
import { truncate } from '@/helpers';
import IconCheckbox from './views/components/IconCheckbox.vue';
import UserTracksListModal from './components/UserTracksListModal/UserTracksListModal';
import { searchUserListTracks } from '@/api/userTracksListApi';
import { CATALOG_INDEX_NAME } from '../../../services/algoliaService';
import { verifyCatalogAccess } from './helpers/verifyCatalogAccess';
import { pick } from 'lodash-es';
import { exclude } from '../../../helpers';
import { initAbortService } from '@/services/abortService';

const { IDLE, PENDING, SUCCESS, ERROR, LOADING_MORE } = API_STATUS;
const statsToFiltersMap = {
  iswcStats: 'multipleIswc',
  registrationStats: 'multipleRegistrations',
  writerStats: 'conflictingWriters',
};

const filtersConfig = {
  multipleIswc: {
    label: 'Multiple ISWCs',
    attributeName: 'flagMultipleIswcs',
    key: 'multipleIswc',
  },
  multipleRegistrations: {
    label: 'Multiple Registrations',
    attributeName: 'flagMultipleSocietyRegistrations',
    key: 'multipleRegistrations',
  },
  conflictingWriters: {
    label: 'Conflicting Writers',
    attributeName: 'flagMultipleWriterHashes',
    key: 'conflictingWriters',
  },
};

class BaseEntity {
  constructor(id, getById) {
    this.id = id;
    this.getById = getById;
  }

  set(data) {
    const item = this.getById(this.id);
    for (const key in data) {
      item[key] = data[key];
    }

    return this;
  }

  get() {
    return this.getById(this.id);
  }

  toJSON() {
    return this.get();
  }
}

class Track extends BaseEntity {
  constructor(id, getById) {
    super(id, getById);
  }
}

class Registration extends BaseEntity {
  constructor(id, getById) {
    super(id, getById);
  }
}

const proxyHandler = {
  set(target, property, value) {
    Reflect.set(target, property, value);
  },
  get(target, property) {
    const instance = target.get() || target.getById(target.id);
    if (property in (instance || {})) {
      return instance[property];
    }
    return Reflect.get(...arguments);
  },
};

const NewTrack = (trackId, getTrackById) => {
  const track = new Track(trackId, getTrackById);
  return new Proxy(track, proxyHandler);
};

const NewRegistration = (registrationId, getRegistrationById) => {
  const registration = new Registration(registrationId, getRegistrationById);
  return new Proxy(registration, proxyHandler);
};

export default {
  name: 'Catalog',
  components: {
    DetailsCard,
    TrackDetailsProvider,
    SelectTracksDownloadProvider,
    IconCheckbox,
    UserTracksListModal,
  },
  props: {},
  data() {
    return {
      query: '',
      page: 0,
      nbHits: 0,
      isLastPage: false,
      tracks: [],
      distinctTracks: [],
      tracksBySource: {},
      sources: [],
      topSources: [],
      sourcesById: {},
      searchTracksStatus: IDLE,
      fetchSourcesStatus: IDLE,
      selectedTrack: null,
      searchStats: null,
      allTracksCount: 0,
    };
  },
  computed: {
    ...apiStatusComputedFactory('searchTracksStatus'),
    ...apiStatusComputedFactory('fetchSourcesStatus'),
    appliedFilters() {
      const filters = [];

      for (const filterKey of Object.keys(filtersConfig)) {
        if (!this.$route.query[filterKey]) continue;
        filters.push(filtersConfig[filterKey]);
      }

      return filters;
    },
    uniqueCompositions() {
      const count =
        !this.query && !this.appliedFilters.length
          ? this.allTracksCount
          : this.nbHits;
      return new Intl.NumberFormat('en-US', {}).format(count);
    },
    searchInputPlaceholder() {
      return this.$route.query.list
        ? `Search ${this.$route.query.list} list...`
        : `Search catalog...`;
    },
    isDemo() {
      return Boolean(
        this.$route.path.startsWith('/d/') && this.$route.params.algolia_index
      );
    },
    searchCatalogTracksAlgoliaIndex() {
      return this.isDemo
        ? this.$route.params.algolia_index
        : CATALOG_INDEX_NAME;
    },
  },
  watch: {
    query(q) {
      this.searchCatalogTracksDebounced(q, 0, this.$route.query.listId);
      this.isLastPage = false;
      this.searchStats = null;
    },
    '$route.path'(path) {
      const viewMode = path.split('/').at(-1);
      if (viewMode !== this.currentViewMode) {
        this.onViewChanged();
      }
      this.currentViewMode = viewMode;
    },
  },
  async created() {
    this.abortService = initAbortService();
    this.filtersConfig = filtersConfig;
    this.searchCatalogTracksAbortMap = {};
    window.tracksById = this.tracksById = {};
    window.registrationsById = this.registrationsById = {};
    this.searchCatalogTracksDebounced = debounce(this.searchCatalogTracks, 500);
    await verifyCatalogAccess(this);
    if (!this.appliedFilters.length && !this.query) {
      this.fetchAllTracksCount();
    }
    this.searchCatalogTracks('', 0, this.$route.query.listId);
    this.fetchSources();
    // this.onSearchByPartyName("Bruno Mars");
  },
  methods: {
    async fetchAllTracksCount() {
      const { response, error } = await withAsync(() => fetchAllTracksCount());

      if (error) {
        console.error(error);
        return;
      }
      this.allTracksCount = response.allTracksCount;
    },
    removeFilter(value) {
      this.$router.push({
        query: {
          ...exclude(this.$route.query, [value.key]),
        },
      });
      this.searchCatalogTracks(this.query, this.page, value.id);
    },
    addFilter(value) {
      const { key } = value;

      const filterQueryKey = statsToFiltersMap[key];

      if (this.$route.query[filterQueryKey]) return;

      this.$router.push({
        query: {
          ...this.$route.query,
          [filterQueryKey]: true,
        },
      });
      this.searchCatalogTracks(this.query, this.page, value.id);
    },
    onSearchByPartyName(party) {
      this.query = party.party;
      this.selectedTrack = null;
      this.$refs.trackDetailsProvider.resetCountriesColour();
      this.$refs.trackDetailsProvider.closeCountryDetailsCard();
      this.$refs.trackDetailsProvider.closeCompareCard();
      this.fetchSearchAnalytics(party);
    },
    async fetchSearchAnalytics(party) {
      console.log('FETCH SEARCH ANALYTICS', party);
      const { response, error } = await withAsync(() =>
        fetchSearchAnalytics({
          publishingPartyObjectID: party.id,
          ipiNameNumber: party.ipi,
          query: party.party,
          algoliaIndex: this.searchCatalogTracksAlgoliaIndex,
          isDemo: this.isDemo,
        })
      );

      this.searchStats = response;
    },
    onChangeMode({ mode, value }) {
      if (mode === 'list') {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            list: value.name,
            listId: value.id,
            listTracksCount: value.tracksCount,
          },
          params: this.$route.params,
        });
        this.query = '';
        this.page = 0;
        this.searchCatalogTracks(this.query, this.page, value.id);
      }
    },
    onSelectAllTracks(checked, addTrackForDownload, resetTracksForDownload) {
      if (!checked) {
        resetTracksForDownload();
        return;
      }
      const tracks = this.distinctTracks.map((track) => {
        return [track, 'objectID'];
      });
      addTrackForDownload(tracks);
    },
    async onDownloadAllRegistrations(downloadSelectedTracks) {
      downloadSelectedTracks(
        (async () => {
          const listId = this.$route.query.listId;

          if (listId) {
            // get tracks for the list
            const { response } = await withAsync(() =>
              searchUserListTracks({
                listId,
                query: this.query,
                perPage: 1000,
                page: 0,
              })
            );
            return response.tracks.hits.reduce((acc, hit) => {
              acc[hit.objectID] = hit;
              return acc;
            }, {});
          } else {
            // get tracks for the catalogue
            const facetFilters = this.prepareFacetFilters();

            const { response, error } = await withAsync(() =>
              searchCatalogTracks({
                query: this.query,
                hitsPerPage: 1000,
                algoliaIndex: this.searchCatalogTracksAlgoliaIndex,
                isDemo: this.isDemo,
                ...(this.$route.query.q && facetFilters.length
                  ? { facetFilters: [facetFilters] }
                  : {}),
                filters: this.appliedFilters
                  .map((filter) => `${filter.attributeName}:true`)
                  .join(' AND '),
              })
            );

            const tracks = response.hits.reduce((acc, hit) => {
              acc[hit.objectID] = hit;
              return acc;
            }, {});

            return tracks;
          }
        })()
      );
    },
    onSearchQueryChange(e, resetTracksForDownload) {
      this.query = e.target.value;
      resetTracksForDownload();
    },
    onViewChanged() {
      const {
        resetCountriesColour,
        closeCountryDetailsCard,
        closeCompareCard,
      } = this.$refs.trackDetailsProvider;
      resetCountriesColour();
      closeCountryDetailsCard();
      closeCompareCard();
      if (this.selectedTrack) {
        this.selectedTrack = null;
      }
    },
    async onTrackSelected({ track, highlightCountries }) {
      const COUNTRIES_COLOUR_MAP = {
        [TRACK_SOURCE_TYPE.MISSING]: '#EDEEF3',
        [TRACK_SOURCE_TYPE.FOUND]: '#B5B9CC',
        [TRACK_SOURCE_TYPE.POTENTIAL_DUPLICATE]: '#F6D2D2',
      };

      const countriesStatus = {};
      const countrySourceCounter = new Set();
      for (const registration of track.registrations) {
        const {
          societyData: { country_tag },
          databaseId,
          society: source,
        } = registration;
        const countrySourceKey = `${country_tag}-${databaseId}`;
        /**
         * The country was not added yet, so we can't have a duplicate for it.
         * Therefore, we just add it as found and add the countrySourceKey to check against later.
         */
        if (
          !(country_tag in countriesStatus) ||
          !countrySourceCounter.has(countrySourceKey)
        ) {
          countriesStatus[country_tag] =
            COUNTRIES_COLOUR_MAP[TRACK_SOURCE_TYPE.FOUND];
          countrySourceCounter.add(countrySourceKey);
          continue;
        }

        /**
         * If we already have the country in countriesStatus we change the colour to duplicate
         */
        countriesStatus[country_tag] =
          COUNTRIES_COLOUR_MAP[TRACK_SOURCE_TYPE.POTENTIAL_DUPLICATE];
      }
      this.selectedTrack = track;
      const countriesToHighlight = Object.entries(countriesStatus).map(
        ([country_tag, countryColour]) => {
          return {
            country_tag,
            countryColour,
          };
        }
      );
      highlightCountries(countriesToHighlight);
    },
    async fetchSources() {
      this.fetchSourcesStatus = PENDING;

      const { response: sources, error } = await withAsync(fetchSources);

      if (error) {
        this.fetchSourcesStatus = ERROR;
        return;
      }

      let allSources = [];
      let topSources = [];
      let sourcesById = {};
      for (const { source, top_market, country_tag, source_id } of sources) {
        let sourceObj = {
          source,
          sourceShort:
            source.length > 7 ? source.slice(0, 7).padEnd(10, '.') : source,
          top_market,
          country_tag,
          source_id,
        };
        allSources.push(sourceObj);
        sourcesById[source_id] = sourceObj;
        if (top_market === 'Yes') {
          topSources.push(sourceObj);
        }
      }

      this.sources = allSources;
      this.topSources = topSources;
      this.sourcesById = sourcesById;
      this.fetchSourcesStatus = SUCCESS;
    },
    getTrackById(id) {
      return this.tracksById[id];
    },
    getRegistrationById(id) {
      return this.registrationsById[id];
    },
    prepareDistinctTracks(tracks) {
      // let obj = {};
      const distinctTracks = [];
      let presentTracksSet = new Set();
      let presentTracksMap = new Map();
      for (const track of tracks) {
        const { originalTitle, registrations } = track;

        const trackKey = String(originalTitle).toLowerCase();
        if (presentTracksMap.has(trackKey)) {
          const existingTrack = presentTracksMap.get(trackKey);

          if (existingTrack.registrations.length < registrations?.length) {
            distinctTracks[existingTrack.index] = track;
          }
          continue;
        }
        presentTracksSet.add(trackKey);
        presentTracksMap.set(trackKey, {
          track,
          index: distinctTracks.length,
          registrations: registrations || [],
        });
        distinctTracks.push(track);
      }

      return distinctTracks;
    },
    prepareTracksBySource(tracks) {
      let obj = {};
      for (const track of tracks) {
        const { objectID, registrations, ...trackDetails } = track;
        for (const registration of registrations) {
          const { society: source } = registration;
          if (!(source in obj)) {
            obj[source] = {};
          }

          if (!(objectID in obj[source])) {
            obj[source][objectID] = track;
          }
        }
      }

      return obj;
    },
    prepareFacetFilters() {
      const catalogFacet = decodeURIComponent(this.$route.query.q);
      const facetsMap = {
        admin: 'publishers.Admin',
        sub: 'publishers.Sub-Publisher',
        original: 'publishers.Original Publisher',
      };

      let publishersFacets = decodeURIComponent(
        this.$route.query.publisherFilters || ''
      );

      if (!publishersFacets) {
        publishersFacets = Object.keys(facetsMap).join('|');
      }

      const selectedFacets = publishersFacets?.split('|');
      const facetFilters =
        selectedFacets.reduce((acc, facet) => {
          if (!(facet in facetsMap)) return acc;
          acc.push(`${facetsMap[facet]}:${catalogFacet}`);
          return acc;
        }, []) || [];

      return facetFilters;
    },
    async searchCatalogTracks(q, page = 0, listId) {
      this.searchTracksStatus = page === 0 ? PENDING : LOADING_MORE;
      this.abortService.abort(this.lastSearchCatalogTracksAbortId);
      const requestAbortId = this.abortService.registerRequest();
      this.lastSearchCatalogTracksAbortId = requestAbortId;
      const facetFilters = listId ? null : this.prepareFacetFilters();
      const { response, error } = await withAsync(() => {
        if (listId) {
          return searchUserListTracks({
            listId,
            query: q,
            page,
          });
        }

        return searchCatalogTracks({
          query: q,
          page,
          // algoliaIndex: this.searchCatalogTracksAlgoliaIndex,
          ...(this.$route.query.q && facetFilters.length
            ? { facetFilters: [facetFilters] }
            : {}),
          isDemo: this.isDemo,
          // TODO: Have a look at how these are populated and update them accordingly to match oss
          filters: this.appliedFilters
            .map((filter) => `${filter.attributeName}:true`)
            .join(' AND '),
        });
      });

      if (this.abortService.didAbort(requestAbortId)) {
        console.warn(`Request aborted - ${requestAbortId}`);
        return;
      }
      if (error) {
        this.searchTracksStatus = ERROR;
        return;
      }

      this.nbHits = listId ? response?.matchingIswcsCount : response.nbHits;
      const hits = response.hits;

      const {
        tracksIds,
        tracksById,
        registrationsById,
        tracksObjectIDs,
        tracksByObjectID,
      } = hits.reduce(
        (acc, track) => {
          const { registrations, ...trackInfo } = track;
          let registrationSocietyCounter = {};
          const updatedRegistrations = [];
          for (const registration of registrations) {
            const { workcode, society } = registration;

            /**
             * Setup an array for the society if it doesn't exist yet
             */
            if (!(registration.society in registrationSocietyCounter)) {
              registrationSocietyCounter[society] = [];
            }

            /**
             * We need to group workcodes under each society
             */
            registrationSocietyCounter[registration.society].push({
              workcode,
              country_tag: registration.societyData.country_tag,
              source_id: registration.societyData.id_society,
              source: society,
            });
            updatedRegistrations.push({
              ...registration,
              workcode,
              country_tag: registration.societyData.country_tag,
              source_id: registration.societyData.id_society,
              source: society,
            });
          }

          const trackDetails = {
            ...trackInfo,
            title: truncate(track.title, 40),
            originalTitle: track.title,
            registrations: updatedRegistrations,
          };

          acc.tracksIds.push(NewTrack(track.objectID, this.getTrackById));
          acc.tracksById[track.objectID] = trackDetails;
          acc.tracksObjectIDs.push(track.objectID);
          acc.tracksByObjectID[track.objectID] = trackDetails;

          return acc;
        },
        {
          tracksIds: [],
          tracksObjectIDs: [],
          tracksById: {},
          tracksByObjectID: {},
          registrationsById: {},
        }
      );
      this.registrationsById = {
        ...this.registrationsById,
        ...registrationsById,
      };

      for (const key in tracksById) {
        this.tracksById[key] = tracksById[key];
      }

      this.tracksById = {
        ...this.tracksById,
        ...tracksById,
      };

      this.tracks = page === 0 ? tracksIds : [...this.tracks, ...tracksIds];

      this.distinctTracks =
        page === 0
          ? this.prepareDistinctTracks(tracksIds)
          : this.prepareDistinctTracks([...this.distinctTracks, ...tracksIds]);
      this.tracksBySource =
        page === 0
          ? this.prepareTracksBySource(tracksIds)
          : {
              ...this.tracksBySource,
              ...this.prepareTracksBySource(tracksIds),
            };

      this.page = page;
      if (listId ? response.hits.length < 20 : response.nbPages === page) {
        this.isLastPage = true;
      }
      this.searchTracksStatus = SUCCESS;
    },
  },
};
</script>
<style lang="scss" module>
.hasFilters {
  .searchStats {
    transform: translateY(-3.75rem);
  }
}
.logoContainer {
  z-index: 10;
  position: absolute;
  top: 5rem;
  right: 5rem;
  width: 10rem;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.logo {
  max-width: 100%;
  display: block;
  height: auto;
}

.pageContent {
  z-index: 50;
  margin-top: 5rem;
  margin-left: 5rem;
}

.filtersContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.searchInputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.matchesFound {
  color: $color-primary;
}

.compositionsFoundContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.noResultsFound {
  margin-top: 2rem;
}

.searchInput {
  font-size: 2.4rem;
  font-weight: bold;
  font-family: $primary-font;
  border: none;
  background: transparent;
}

.selectedTrackBlock {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.closeSelectedTrackBtn {
  border: none;
  background: transparent;
  font-size: 2rem;
  color: $color-grey-light;
  cursor: pointer;
}

.selectedTrack {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.selectedTrackTitle {
  font-size: 1.8rem;
  font-weight: 600;
}

.selectedTrackIswc {
  display: block;
}

.selectedTrackIswc {
  color: $color-grey-light;
}

.trackRegistrationsSummary {
  color: $color-grey-light;
}
</style>
