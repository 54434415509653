import { functions } from '@/config/firebase.config';

export const searchWorkRegistrations = (code) => {
  return functions
    .httpsCallable('searchWorkRegistrations')({ code })
    .then(({ data }) => data.data);
};

export const searchWorkRegistrationsByCountry = (code, country) => {
  return functions
    .httpsCallable('searchWorkRegistrationsByCountry')({ code, country })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const searchTrackDetailsByWorkcode = ({
  code,
  workcodes,
  source_id,
  allTracksWorkcodes,
}) => {
  return functions
    .httpsCallable('searchTrackDetailsByWorkcode')({
      code,
      workcodes,
      source_id,
      allTracksWorkcodes,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};
