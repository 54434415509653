import { nanoid } from 'nanoid';

export const initAbortService = (name) => {
  let requests = {};

  /**
   *
   * @returns string - request abort controller id
   */
  const registerRequest = () => {
    const id = nanoid();
    const abortController = new AbortController();
    requests[id] = abortController;
    return id;
  };

  /**
   *
   * @param {String} id
   * @returns void
   */
  const abort = (id) => {
    if (!id) return;
    if (requests[id]) {
      requests[id].abort();
    }
  };

  const abortAll = () => {
    for (const abortController of Object.values(requests)) {
      abortController.abort();
    }
  };

  /**
   *
   * @param {String} id
   * @returns Boolean
   */
  const didAbort = (id) => {
    return !(id in requests) || requests[id].signal.aborted;
  };

  /**
   *
   * @param {string} id
   * @returns AbortController | undefined
   */
  const getAbortController = (id) => {
    return requests[id];
  };

  const reset = () => {
    abortAll();
    requests = {};
  };

  /**
   * @param {string} id
   */
  const remove = (id) => {
    delete requests[id];
  };

  return {
    didAbort,
    registerRequest,
    abort,
    abortAll,
    getAbortController,
    reset,
  };
};
