var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SelectTracksDownloadProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var selectedTracksForDownload = ref.selectedTracksForDownload;
    var addTrackForDownload = ref.addTrackForDownload;
    var removeTrackForDownload = ref.removeTrackForDownload;
    var selectedTracksForDownloadCount = ref.selectedTracksForDownloadCount;
    var downloadSelectedTracks = ref.downloadSelectedTracks;
    var downloadStatus = ref.downloadStatus;
    var resetTracksForDownload = ref.resetTracksForDownload;
return [_c('TrackDetailsProvider',{ref:"trackDetailsProvider",attrs:{"selectedTrack":_vm.selectedTrack,"tracksBySource":_vm.tracksBySource,"sourcesById":_vm.sourcesById},on:{"onSearchByPartyName":_vm.onSearchByPartyName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var openCountryDetails = ref.openCountryDetails;
        var searchCountries = ref.searchCountries;
        var highlightCountries = ref.highlightCountries;
        var resetCountriesColour = ref.resetCountriesColour;
        var closeCountryDetailsCard = ref.closeCountryDetailsCard;
        var closeCompareCard = ref.closeCompareCard;
return [_c('UserTracksListModal',{attrs:{"tracks":selectedTracksForDownload},on:{"changeMode":_vm.onChangeMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var showTracksListModal = ref.showTracksListModal;
return [_c('div',{class:[_vm.appliedFilters.length && _vm.$style.hasFilters]},[_c('div',{class:_vm.$style.logoContainer},[_c('img',{class:_vm.$style.logo,attrs:{"src":require("@/assets/img/logo-sm.png"),"alt":"Rightsholders Logo"}})]),_c('div',{class:_vm.$style.pageContent},[(_vm.selectedTrack)?_c('div',{class:_vm.$style.selectedTrackBlock},[_c('button',{class:_vm.$style.closeSelectedTrackBtn,on:{"click":function($event){(_vm.selectedTrack = null),
                        resetCountriesColour(),
                        closeCountryDetailsCard(),
                        closeCompareCard()}}},[_c('BaseIcon',{attrs:{"icon":"times"}})],1),_c('div',{class:_vm.$style.selectedTrack},[_c('span',{class:_vm.$style.selectedTrackTitle},[_vm._v(" "+_vm._s(_vm.selectedTrack.title)+" ")]),_c('span',{class:_vm.$style.trackRegistrationsSummary},[_vm._v(_vm._s(Array.isArray(_vm.selectedTrack.registrations) ? _vm.selectedTrack.registrations.length : 0)+" registrations")])])]):[_c('div',{class:_vm.$style.searchInputContainer},[_c('input',{class:[
                        _vm.$style.searchInput,
                        'h-[42px] placeholder:text-[#6B696B]' ],attrs:{"type":"text","placeholder":_vm.searchInputPlaceholder},domProps:{"value":_vm.query},on:{"input":function($event){return _vm.onSearchQueryChange($event, resetTracksForDownload)}}}),(_vm.appliedFilters.length)?_c('div',{class:_vm.$style.filtersContainer},[_c('span',{class:_vm.$style.matchesFound},[_vm._v(" Filters: ")]),_vm._l((_vm.appliedFilters),function(filterValue,filterKey){return _c('div',{key:((filterValue.key) + "-" + filterKey),attrs:{"variant":"primary-outline"}},[_c('BasePill',{attrs:{"variant":"primary-outline","isClearable":""},on:{"clear":function($event){return _vm.removeFilter(filterValue)}}},[_vm._v(" "+_vm._s(filterValue.label)+" ")])],1)})],2):_vm._e(),(_vm.nbHits)?_c('div',{class:_vm.$style.compositionsFoundContainer},[(_vm.nbHits <= 1000)?_c('div',[_c('IconCheckbox',{attrs:{"checked":_vm.distinctTracks.length ===
                              selectedTracksForDownloadCount},on:{"change":function($event){return _vm.onSelectAllTracks(
                              $event,
                              addTrackForDownload,
                              resetTracksForDownload
                            )}}})],1):_vm._e(),_c('span',{class:_vm.$style.matchesFound},[_c('b',[_vm._v(_vm._s(_vm.uniqueCompositions))]),_vm._v(" Unique Compositions ")])]):_vm._e()])],(_vm.tracks.length && _vm.fetchSourcesStatusSuccess)?_c('router-view',{attrs:{"searchStatsClass":_vm.$style.searchStats,"tracks":_vm.tracks,"distinctTracks":_vm.distinctTracks,"sources":_vm.sources,"topSources":_vm.topSources,"tracksBySource":_vm.tracksBySource,"selectedTrack":_vm.selectedTrack,"isLastPage":_vm.isLastPage,"selectedTracksForDownload":selectedTracksForDownload,"addTrackForDownload":addTrackForDownload,"removeTrackForDownload":removeTrackForDownload,"selectedTracksForDownloadCount":selectedTracksForDownloadCount,"downloadStatus":downloadStatus,"allRegistrationsCount":_vm.nbHits <= 1000 ? _vm.nbHits : 0,"isDemo":_vm.isDemo,"searchStats":_vm.selectedTrack ? null : _vm.searchStats,"appliedFilters":_vm.appliedFilters},on:{"showTracksListModal":function($event){return showTracksListModal('SAVE_TRACKS')},"openSearchListsModal":function($event){return showTracksListModal('SEARCH_LISTS')},"openCountryDetails":openCountryDetails,"loadMoreTracks":function($event){return _vm.searchCatalogTracks(_vm.query, _vm.page + 1, _vm.$route.query.listId)},"downloadSelectedTracks":downloadSelectedTracks,"downloadAllRegistrations":function($event){return _vm.onDownloadAllRegistrations(downloadSelectedTracks)},"resetSelectedTracks":resetTracksForDownload,"trackSelected":function($event){return _vm.onTrackSelected({
                      track: $event,
                      highlightCountries: highlightCountries,
                      searchCountries: searchCountries,
                    })},"addFilter":_vm.addFilter}}):(
                    !_vm.fetchSourcesStatusPending && !_vm.searchTracksStatusPending
                  )?_c('div',{class:_vm.$style.noResultsFound},[_c('span',[_vm._v("No results found")])]):_vm._e()],2)])]}}],null,true)})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }